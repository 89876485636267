<template>
  <section>
    <!-- LANDING -->
    <div class="home pt-5">
      <div class="container">
        <h1 class="content-header">Welcome to this section on community resilience</h1>
        <!-- CONTENT -->
        <div class="content-wrapper">
          <div class="row">
            <div class="col-sm order-sm-1 ">
              <button class="btn" @click="openPopup = true">
                <img
                  src="@/assets/img/_content/community-landing-lightbox.png"
                  class="img-fluid"
                  alt="Bio"
                >
              </button>
            </div>
            <div class="col-sm">
              <div class="row align-items-center">
                <!-- <div class="col-sm">
                  <img
                    src="@/assets/img/_content/personal-learn-1.jpg"
                    class="img-thumb img-fluid"
                    alt=""
                  >
                </div> -->
                <div class="col-sm">
                  <h2 class="pt-4">Meet Rick and Nadine </h2>
                  <p>Rick Ezekiel, PhD, (he/him) is the Director of Equitable Learning, Health and Wellness at Centennial College (Toronto, Canada). Rick holds a BA in Psychology and MSc in Neuroscience (Western University), and PhD in Developmental Psychology and Education (The University of Toronto). At the centre of Rick’s professional and scholarly work is a core passion to enhance the role education can play in promoting equitable lifespan development outcomes for learners within our broader communities and society. Rick’s research areas focus on mental health disparities within marginalized communities, the impact of mental health disparities on learning and academic performance, influences of early childhood adversity on emerging adult mental health, and neurobiological mechanisms subserving the development of attentional control, cognitive control, and self-regulation capacities from childhood to young adulthood. Rick has held several administrative leadership appointments at Western University and Centennial College, and has played a significant role in a number of equity focused initiatives, including the development of Western's Indigenous Strategic Plan, Student Mental Health Strategic Plan, and the revitalization of mental health and accessible learning services at Centennial College. Rick is a cisgender, queer man of European (settler) ancestry, respectfully and gratefully doing research and work within the traditional territories of the Mississaugas of the Credit First Nation, in the Dish with One Spoon Treaty Region.</p>
                  <p>Nadine de Gannes is Assistant Professor in Managerial Accounting and Control and Sustainability, and the Associate Faculty Director of the HBA Program at Ivey Business School, Western University. Nadine holds a Masters and PhD in Accounting from the London School of Economics and Political Science, and an HBA from Ivey Business School.</p>
                  <p>In taking a sociological perspective in both her research and teaching, Nadine explores the interrelationships between accounting, power, and perceptions of worth. For her doctoral research, Nadine studied executive compensation, the rise of executive pay consultants and pay benchmarking practices. Her research interests encompass the integration of environmental, social and governance (ESG) considerations into executive compensation, management control systems and organizational culture.</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-sm order-sm-1">
              <img
                src="@/assets/img/_content/community-landing-lightbox2.png"
                class="img-fluid ps-3"
                alt="Bio"
              >
            </div>
            <div class="col-sm ">
              <h2 class="pt-4">Meet Nadine </h2>
              <p>Nadine de Gannes is Assistant Professor in Managerial Accounting and Control and Sustainability, and the Associate Faculty Director of the HBA Program at Ivey Business School, Western University. Nadine holds a Masters and PhD in Accounting from the London School of Economics and Political Science, and an HBA from Ivey Business School.</p>
              <p>In taking a sociological perspective in both her research and teaching, Nadine explores the interrelationships between accounting, power, and perceptions of worth. For her doctoral research, Nadine studied executive compensation, the rise of executive pay consultants and pay benchmarking practices. Her research interests encompass the integration of environmental, social and governance (ESG) considerations into executive compensation, management control systems and organizational culture.</p>
            </div>
          </div> -->
        </div>
      </div>

      <!-- ROUTING NAV -->
      <div
        class="routing-nav"
        id="route02"
      >
        <h2>How would you like to support your students today?</h2>
        <div>
          <router-link to="community/learn" class="routing-nav__btn routing-nav__btn--large">
            <strong>Learn</strong> about community resilience
          </router-link>
          <router-link to="community/discuss" class="routing-nav__btn routing-nav__btn--large">
            <strong>Discuss</strong> resilience with students
          </router-link>
          <router-link to="community/share" class="routing-nav__btn routing-nav__btn--large">
            <strong>Share</strong> resources with students
          </router-link>
          <router-link to="community/design" class="routing-nav__btn routing-nav__btn--large">
            <strong>Design</strong> curriculum that promotes resilience
          </router-link>
          <router-link to="community/review" class="routing-nav__btn routing-nav__btn--large">
            <strong>Review</strong> research by resilience experts
          </router-link>
        </div>
      </div>

      <!-- MODAL -->
      <modal-popup v-if="openPopup" @close="openPopup = false">
        <div class="video-player">
          <!-- video element -->
          <!-- <vue-plyr>
            <video
              controls
              crossorigin
              playsinline
            >
              <source
                size="720"
                src="https://staging.gevc.ca/centennial/tic/videos/personal/learn/1.mp4"
                type="video/mp4"
              />
              <track
                label="English"
                kind="subtitles"
                srclang="en"
                src="https://staging.gevc.ca/centennial/tic/cc/community/landing/01.en.vtt"
                default
              >
            </video>
          </vue-plyr> -->
          <div class="ratio ratio-16x9">
  <iframe
    src="https://www.youtube.com/embed/A33djGeOPQg?rel=0&cc_load_policy=1"
    title="YouTube video"
    allowfullscreen
  ></iframe>
</div>
          <div class="accordion">
            <accordion
              headerText="Transcript"
              accordionID="transcript01"
            >
                <p>Hi there and welcome to the Community Resilience section of our Thriving in the Classroom toolkit.  </p>
                <p>My name is Dr. Rick Ezekiel and I'm the director of Equitable Learning, Health and Wellness at Centennial College, and background training in applied psychology and developmental cognitive neuroscience. And I do work in sort of interdisciplinary developmental sciences.  </p>
                <p>We're excited that you've joined us in the toolkit, and particularly the Community Resilience section of the toolkit.  </p>
                <p>When we talk about the idea of resilience, we know that resilience relies upon students, individuals, humans having supportive connections, relationships and communities that support their individual ability to be resilient in the face of stress or in the face of adversity. This is especially important for students who might have different developmental vulnerabilities, including childhood adversity, or for students who might be navigating additional barriers in our institutions or in our communities more broadly, including disability-related barriers, barriers associated with discrimination, minority stress impacting different minority communities. And that's what this section of our toolkit is all about. It's talking about how we can see our classroom as a supportive community that enhances individual students’ ability to be resilient. </p>
                <p>Within this section, we'll explore strategies that can support sense of belonging within the classroom, where students feel like they can connect with their peers, where they feel like they can connect with their professor, yourself, in ways that are supportive and that really meet that human need for connection and relationship, and that are also empathetic and understanding of the unique circumstances that a student might be facing.  </p>
                <p>We'll also explore a lot of concepts related to marginalization or barriers that might prevent students from feeling included or forming a sense of belonging within the classroom or within our broader institutional community. So these include explorations of different types of identity factors that might drive marginalization, whether students are experiencing racism or experiencing homophobia, transphobia, disability related barriers, et cetera, et cetera, while navigating the classroom environment. All of these really impact whether students will experience our community within the classroom as a supportive one.  </p>
                <p>The other thing we always need to be mindful of is that students bring a lot of resilience with them into the classroom, and in many instances, their communities themselves are sources of resilience. I think particularly, Indigenous communities and the sense of collectivist values and connection to community, to land, to language, to culture. So those are really significant sources of community resilience. And there's also been lots of research done in racialized communities, in queer communities, that shows when students and individuals have an opportunity to connect with individuals who share identity, who share experiences that can be a really great source of collective meaning-making. And when students can access those communities or can bring their identities and experiences with them into the classroom, that's another tool that we can use to really ensure that students can form that sense of belonging to feel seen, to feel heard, to bring their whole humanness into the classroom.  </p>
                <p>So I wish you well in exploring the resources within this section and thank you for your interest and stay well and thanks for all the work that you're doing to support student resilience.  </p>
            </accordion>
          </div>
          <p><a :href="`${publicPath}docs/community/Meet-Rick.pdf`" target="_blank">Open transcript in a new tab</a></p>
        </div>
      </modal-popup>

      <!-- MAIN NAV -->
      <nav class="navbar main-nav">
        <div class="container">
          <router-link class="main-nav__item" to="/students-resilience/community">COMMUNITY</router-link>
          <router-link class="main-nav__item" to="/students-resilience/personal">PERSONAL</router-link>
          <router-link class="main-nav__item" to="/students-resilience/academic">ACADEMIC</router-link>
          <router-link class="main-nav__item" to="/students-resilience/career">CAREER</router-link>
        </div>
      </nav>

    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
import ModalPopup from '@/components/ModalPopup.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation
//   // Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  components: {
    // SidebarMenu,
    Accordion,
    ModalPopup
    // Hooper,
    // Slide,
    // HooperNavigation
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      hooperSettings: {
        itemsToShow: 4,
        trimWhiteSpace: true,
        centerMode: false,
        wheelControl: false,
        breakpoints: {
          800: {
            itemsToShow: 1
          }
        }
      },

      openPopup: false
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
